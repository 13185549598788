import { globalWindow } from '@emico-utils/ssr-utils'

export function toggleChat() {
  if (!globalWindow) {
    return
  }

  if (!('zE' in globalWindow)) {
    return
  }

  if (typeof globalWindow.zE !== 'function') {
    return
  }

  globalWindow.zE('webWidget', 'show')
  globalWindow.zE('webWidget', 'open')
}
