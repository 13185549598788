import styled from '@emotion/styled'

import theme from '../../theme'

const Container = styled('div', {
  shouldForwardProp: (prop) => !['hasNoPadding'].includes(prop.toString()),
})<{ hasNoPadding?: boolean }>`
  margin: 0 auto;
  padding: ${({ hasNoPadding }) => !hasNoPadding && `0 ${theme.spacing.x2}`};
  width: 100%;
  max-width: ${theme.sizes.containerWidth};
`

export default Container
