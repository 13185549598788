import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { minWidth } from '@emico/styles'

import Meta from './Meta'
import MetaCanonical from './MetaCanonical'
import FooterConfigurator from '../feature/configurator/FooterConfigurator'
import HeaderConfigurator from '../feature/configurator/HeaderConfigurator'
import getSeoPageDescription from '../lib/getSeoPageDescription'
import theme from '../theme'

const Layout = styled('div', {
  shouldForwardProp: (prop) => !['hasOverscroll'].includes(prop.toString()),
})<{ hasOverscroll: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: ${({ hasOverscroll }) => hasOverscroll && '100vh'};
  overscroll-behavior: ${({ hasOverscroll }) => hasOverscroll && 'contain'};
  overflow: ${({ hasOverscroll }) => hasOverscroll && 'hidden'};
`

export const Main = styled('main', {
  shouldForwardProp: (prop) =>
    !['hasPaddingBottom', 'hasPaddingTop', 'hasOverscroll'].includes(
      prop.toString(),
    ),
})<{
  hasPaddingBottom: boolean
  hasPaddingTop: boolean
  hasOverscroll: boolean
}>`
  padding-bottom: ${({ hasPaddingBottom }) =>
    hasPaddingBottom && theme.pagePaddingSm};
  padding-top: ${({ hasPaddingTop }) => hasPaddingTop && theme.pagePaddingSm};
  height: 100%;
  overscroll-behavior: ${({ hasOverscroll }) => hasOverscroll && 'contain'};
  overflow: ${({ hasOverscroll }) => hasOverscroll && 'auto'};

  @media ${minWidth('lg')} {
    padding-bottom: ${({ hasPaddingBottom }) =>
      hasPaddingBottom && theme.pagePadding};
    padding-top: ${({ hasPaddingTop }) => hasPaddingTop && theme.pagePadding};
  }
`

interface Props {
  children: ReactNode
  pageType?: 'default' | 'configurator'
  hasOverscroll?: boolean
  showFooter?: boolean
  hasPaddingBottom?: boolean
  hasPaddingTop?: boolean
  metaTitle?: string
  metaDescription?: string
  showMetaCanonical?: boolean
}

const DefaultLayout = ({
  children,
  pageType = 'default',
  hasOverscroll = false,
  showFooter = true,
  hasPaddingBottom = true,
  hasPaddingTop = true,
  metaTitle,
  metaDescription,
  showMetaCanonical = true,
  ...other
}: Props) => (
  /**
   * TODO
   * - Default header for non-configurator pages
   * - Default footer for non-configurator pages
   */

  <>
    {metaTitle && (
      <Meta
        metaTitle={metaTitle}
        metaDescription={metaDescription ?? getSeoPageDescription()}
      />
    )}

    {showMetaCanonical && <MetaCanonical />}

    <Layout hasOverscroll={hasOverscroll} {...other}>
      {pageType === 'default' ? <></> : <HeaderConfigurator />}

      <Main
        hasPaddingBottom={hasPaddingBottom}
        hasPaddingTop={hasPaddingTop}
        hasOverscroll={hasOverscroll}
      >
        {children}
      </Main>

      {showFooter && (pageType === 'default' ? <></> : <FooterConfigurator />)}
    </Layout>
  </>
)

export default DefaultLayout
