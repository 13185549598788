import { Choices, Data, Position, ProductChoice } from './ConfiguratorContext'
import { Products } from './OverviewContext'
import { Product } from '../product/product'

/**
 * @param positions array of Position to map over
 * @returns a string with all positions
 */
export const stringifyPositions = (positions: Position[]): string =>
  `[${positions.map(
    (obj) =>
      `{"windDirection": "${obj.windDirection}","quantity": ${obj.quantity}}`,
  )}]`

/**
 * @param positions array of Position to filter
 * @returns filtered array of positions with quantity
 */
export const filterPositionsWithQuantity = (
  positions: Position[],
): Position[] => positions.filter((pos) => pos.quantity !== 0)

/**
 * @param productChoices
 * @returns
 */
export const getEnabled = (productChoices: ProductChoice[] | undefined) =>
  productChoices?.some((pc) => pc && pc.amount && pc.amount > 0)
    ? 'true'
    : 'false'

/**
 * @param choices
 * @param data
 * @returns
 */
export const calculateSolarPanelPrice = (
  choices: Choices,
  data: Data | Products,
) => {
  let amount: number

  if (choices.solarPanelAmount) {
    amount = choices.solarPanelAmount
  } else {
    amount = choices.solarPanelPositions
      ? choices.solarPanelPositions?.reduce(
          (prev, cur) => prev + cur.quantity,
          0,
        )
      : 0
  }

  const solarPanelPrice = data.solarPanel?.price ? data.solarPanel.price : 0

  return solarPanelPrice * amount
}

/**
 * @param choices
 * @param data
 * @returns
 */
const calculateInverterModelPrice = (
  choices: Choices,
  data: Data | Products,
) => {
  const inverterModelAmount = choices.inverterModelAmount
    ? choices.inverterModelAmount
    : 0

  const inverterModelProduct = data.inverterModels?.find(
    (mod) => mod && mod.id === choices.inverterModel,
  )

  const inverterModelPrice = inverterModelProduct?.price
    ? inverterModelProduct?.price
    : 0

  return inverterModelAmount * inverterModelPrice
}

/**
 *
 * @param choices
 * @param data
 * @returns
 */
const calculateInverterMonitorPrice = (
  choices: Choices,
  data: Data | Products,
) => {
  const inverterMonitoringAmount = choices.inverterMonitorAmount
    ? choices.inverterMonitorAmount
    : 1

  const inverterMonitoringProduct = data.inverterMonitors?.find(
    (mon) => mon && mon.id === choices.inverterMonitor,
  )

  const inverterMonitoringPrice = inverterMonitoringProduct?.price
    ? inverterMonitoringProduct.price
    : 0

  return inverterMonitoringAmount * inverterMonitoringPrice
}

/**
 *
 * @param choices
 * @param data
 * @returns
 */
const calculateInverterStoragePrice = (
  choices: Choices,
  data: Data | Products,
) => {
  const inverterStorageingAmount = choices.inverterStorageAmount
    ? choices.inverterStorageAmount
    : 1

  const inverterStorageingProduct = data.inverterStorages?.find(
    (mon) => mon && mon.id === choices.inverterStorage,
  )

  const inverterStorageingPrice = inverterStorageingProduct?.price
    ? inverterStorageingProduct.price
    : 0

  return inverterStorageingAmount * inverterStorageingPrice
}

/**
 *
 * @param productChoices
 * @param data
 * @returns
 */
const calculatePriceOfPriceChoice = (
  productChoices: Array<ProductChoice | null>,
  data: Product[] | undefined,
) => {
  let totalPrice = 0

  if (
    productChoices?.length > 0 &&
    productChoices.some(
      (ch) =>
        ch !== null &&
        ch !== undefined &&
        ch.amount !== undefined &&
        ch.amount > 0,
    )
  ) {
    data?.forEach((pr) => {
      if (pr.price) {
        const amount = productChoices.find((ch) => ch && ch.id === pr.id)
          ?.amount

        if (amount) {
          const prPrice = pr.price * amount

          totalPrice = totalPrice + prPrice
        }
      }
    })
  }

  return totalPrice
}

/**
 *
 * @param choices
 * @param data
 * @returns
 */
const calculateACMaterialPrice = (choices: Choices, data: Data | Products) =>
  calculatePriceOfPriceChoice(choices.acMaterials, data.acMaterials)

/**
 *
 * @param choices
 * @param data
 * @returns
 */
const calculateAccessoriesPrice = (choices: Choices, data: Data | Products) =>
  calculatePriceOfPriceChoice(choices.accessories, data.accessories)

export const calculateEsdecPrice = (choices: Choices, data: Data | Products) =>
  calculatePriceOfPriceChoice(choices.mountingEsdec, data.mountingEsdec)

export const calculateCPXPrice = (choices: Choices, data: Data | Products) =>
  calculatePriceOfPriceChoice(choices.mountingCPX, data.mountingCPX)

export const calculateElektramatPrice = (
  choices: Choices,
  data: Data | Products,
) => {
  const elektramatConfiguration =
    'elektramatModel' in data ? data.elektramatModel : undefined

  return choices.elektramatModel && elektramatConfiguration
    ? elektramatConfiguration?.subTotal?.value ?? 0
    : 0
}

/**
 * @param choices
 * @param data
 * @returns
 */
export const calculateTotalPrice = (
  choices: Choices,
  data: Data | Products,
) => {
  const solarPanelsPrice = calculateSolarPanelPrice(choices, data)

  const inverterModelPrice = calculateInverterModelPrice(choices, data)

  const inverterMonitoringFinalPrice = calculateInverterMonitorPrice(
    choices,
    data,
  )

  const inverterStorageFinalPrice = calculateInverterStoragePrice(choices, data)

  const acMaterialPrice = calculateACMaterialPrice(choices, data)

  const accessoriesPrice = calculateAccessoriesPrice(choices, data)

  const esdecPrice = calculateEsdecPrice(choices, data)

  const cpxPrice = calculateCPXPrice(choices, data)

  const elektramatPrice = calculateElektramatPrice(choices, data)

  return (
    solarPanelsPrice +
    inverterModelPrice +
    inverterMonitoringFinalPrice +
    inverterStorageFinalPrice +
    acMaterialPrice +
    accessoriesPrice +
    esdecPrice +
    cpxPrice +
    elektramatPrice
  )
}
