/* eslint-disable no-console */
import { isDebugEnabled } from './useDebug'
import {
  Choices,
  Data as ConfiguratorContextData,
} from '../context/ConfiguratorContext'
import { Products } from '../context/OverviewContext'

interface Other {
  totalEsdecProducts: number
  totalCPXProducts: number
  esdecPrice: number
  cpxPrice: number
  totalPrice: number
}

export const debugConfigurator = (
  choices: Choices,
  data: ConfiguratorContextData,
  other: Other,
) => {
  if (!isDebugEnabled) {
    return
  }

  console.debug('Configurator - choices')
  console.table({ ...choices, solarPanelPositions: 'see later debug' })

  console.debug('Configurator - choices - solarPanelPositions')
  console.table(choices.solarPanelPositions)

  console.debug('Configurator - data - solarPanel')
  console.table(
    [data.solarPanel],
    ['articleNumber', 'ean', 'id', 'name', 'sku', 'price'],
  )

  console.debug('Configurator - data - inverterTypes')
  console.table(data.inverterTypes)

  console.debug('Configurator - data - inverterModel')
  console.table([data.inverterModel])

  console.debug('Configurator - data - inverterModels')
  console.table(data.inverterModels, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'price',
  ])

  console.debug('Configurator - data - inverterMonitors')
  console.table(data.inverterMonitors, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'bestChoice',
    'price',
  ])

  console.debug('Configurator - data - acMaterials')
  console.table(data.acMaterials, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'price',
  ])

  console.debug('Configurator - data - accessories')
  console.table(data.accessories, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'price',
  ])

  console.debug('Configurator - data - mountingEsdec')
  console.table(data.mountingEsdec)

  console.debug('Configurator - data - mountingCPX')
  console.table(data.mountingCPX)

  console.debug('Configurator - other')
  console.table(other)
}

export const debugConfiguratorPage = (
  initialChoices: Choices,
  initialData: ConfiguratorContextData | Products,
) => {
  if (!isDebugEnabled) {
    return
  }
  console.debug('ConfiguratorPage - initialChoices')
  console.table({ ...initialChoices, solarPanelPositions: 'see later debug' })

  console.debug('ConfiguratorPage - initialChoices - solarPanelPositions')
  console.table(initialChoices.solarPanelPositions)

  console.debug('ConfiguratorPage - initialData - solarPanel')
  console.table(
    [initialData.solarPanel],
    ['articleNumber', 'ean', 'id', 'name', 'sku', 'price'],
  )

  if ('inverterTypes' in initialData) {
    console.debug('ConfiguratorPage - initialData - inverterTypes')
    console.table(initialData.inverterTypes)
  }

  if ('inverterModel' in initialData) {
    console.debug('ConfiguratorPage - initialData - inverterModel')
    console.table([initialData.inverterModel])
  }

  console.debug('ConfiguratorPage - initialData - inverterModels')
  console.table(initialData.inverterModels, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'price',
  ])

  console.debug('ConfiguratorPage - initialData - inverterMonitors')
  console.table(initialData.inverterMonitors, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'bestChoice',
    'price',
  ])

  console.debug('ConfiguratorPage - initialData - acMaterials')
  console.table(initialData.acMaterials, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'price',
  ])

  console.debug('ConfiguratorPage - initialData - accessories')
  console.table(initialData.accessories, [
    'articleNumber',
    'ean',
    'id',
    'name',
    'sku',
    'price',
  ])

  console.debug('ConfiguratorPage - initialData - mountingEsdec')
  console.table(initialData.mountingEsdec)

  console.debug('ConfiguratorPage - initialData - mountingCPX')
  console.table(initialData.mountingCPX)
}
