import { useApolloClient } from '@apollo/client'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Control as ControlType,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form'

import {
  calculateEsdecPrice,
  calculateCPXPrice,
  calculateTotalPrice,
} from './helpers'
import useLocale from '../../lib/useLocale'
import { ACMaterial } from '../ac-material/get'
import { debugConfigurator } from '../debug/debugConfigurator'
import { useDebug } from '../debug/useDebug'
import { ElektramatConfigurationFragment } from '../elektramat/fragments.generated'
import { useStoreData } from '../hash/useStoreData'
import { getInverterModels, getInverterTypes } from '../inverter/get'
import {
  getACMaterialsPrices,
  getProduct,
  getProductPrice,
  getProductPrices,
} from '../product/get'
import { Product } from '../product/product'
import { useGetProduct } from '../product/useGetProduct'

export interface Position {
  displayName: string
  windDirection: string
  quantity: number
}

export interface ProductChoice {
  id: string
  sku?: string
  amount: number
}

export interface Choices {
  solarPanel: string | null
  solarPanelPositions: Position[]
  solarPanelAmount?: number
  elektramatModel: string | null
  elektramatQuantity: string | null
  inverterType: string | null
  inverterModel: string | null
  inverterModelAmount?: number
  inverterMonitor?: string | null
  inverterMonitorAmount?: number
  inverterStorage?: string | null
  inverterStorageAmount?: number
  acMaterialEnabled?: 'true' | 'false'
  acMaterials: Array<ProductChoice | null>
  accessoriesEnabled?: 'true' | 'false'
  accessories: Array<ProductChoice | null>
  mountingEsdec: ProductChoice[]
  mountingCPX: ProductChoice[]
  mountingEsdecEnabled?: 'true' | 'false'
  mountingCPXEnabled?: 'true' | 'false'
  elektramatEnabled?: 'true' | 'false'
}

interface ElektramatConfiguration extends ElektramatConfigurationFragment {
  quantity: string | null
}

export interface Data {
  solarPanel: Product | null
  elektramatModel: ElektramatConfiguration | null
  inverterTypes: string[]
  inverterModel: Product | null
  inverterModels: Product[]
  inverterMonitors: Product[]
  inverterStorages: Product[]
  acMaterials: ACMaterial[]
  accessories: Product[]
  mountingEsdec: Product[]
  mountingCPX: Product[]
}

interface ConfiguratorContextInterface {
  data?: Data
  setData?: Dispatch<SetStateAction<Data>>
  choices?: Choices
  register?: UseFormRegister<Choices>
  unregister?: UseFormUnregister<Choices>
  watch?: UseFormWatch<Choices>
  control?: ControlType<Choices>
  setValue?: UseFormSetValue<Choices>
  esdecPrice: number
  cpxPrice: number
  totalPrice: number
  totalEsdecProducts: number
  totalCPXProducts: number
}

const ConfiguratorContext = createContext<ConfiguratorContextInterface>({
  esdecPrice: 0,
  cpxPrice: 0,
  totalPrice: 0,
  totalEsdecProducts: 0,
  totalCPXProducts: 0,
})

interface Props {
  children: JSX.Element
  data: Data
  choices: Choices
}

export const ConfiguratorProvider = ({
  children,
  data: initialData,
  choices: initialChoices,
}: Props) => {
  const client = useApolloClient()
  const locale = useLocale()

  const [initialized, setInitialized] = useState(false)
  const [data, setData] = useState<Data>(initialData)
  const { register, unregister, watch, control, setValue } = useForm<Choices>({
    defaultValues: initialChoices,
  })

  const storeData = useStoreData()
  const queryGetProduct = useGetProduct()

  const isDebug = useDebug()

  const choices = watch()

  const getDataPrices = useCallback(async () => {
    let solarPanel = data.solarPanel

    if (solarPanel) {
      solarPanel = await getProductPrice(client, solarPanel)
    }

    let inverterModel = data.inverterModel

    if (inverterModel) {
      inverterModel = await getProductPrice(client, inverterModel)
    }

    const inverterModels = await getProductPrices(client, data.inverterModels)

    const inverterMonitors = await getProductPrices(
      client,
      data.inverterMonitors,
    )

    const inverterStorages = await getProductPrices(
      client,
      data.inverterStorages,
    )

    const acMaterials = await getACMaterialsPrices(client, data.acMaterials)

    const accessories = await getProductPrices(client, data.accessories)

    const mountingEsdec = await getProductPrices(client, data.mountingEsdec)

    const mountingCPX = await getProductPrices(client, data.mountingCPX)

    return {
      ...data,
      solarPanel,
      inverterModel,
      inverterModels,
      inverterMonitors,
      inverterStorages,
      acMaterials,
      accessories,
      mountingEsdec,
      mountingCPX,
    }
  }, [client, data])

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      getDataPrices().then((res) => {
        setData(res)
      })
    }
  }, [getDataPrices, initialized])

  useEffect(
    () =>
      watch((values) => {
        storeData({
          data: JSON.stringify(values),
        })
      }).unsubscribe,
    [storeData, watch],
  )

  useEffect(
    () =>
      watch((values) => {
        if (values.inverterModel && locale) {
          getProduct(client, values.inverterModel, locale).then((product) => {
            if (product) {
              setData((prevData) => ({
                ...prevData,
                inverterModel: product,
              }))
            }
          })
        }
      }).unsubscribe,
    [client, queryGetProduct, watch, locale],
  )

  const totalEsdecProducts = choices.mountingEsdec
    .filter(stripMaybes)
    .filter((ac) => ac !== undefined)
    .reduce((prev, cur) => prev + cur.amount, 0)

  const totalCPXProducts = choices.mountingCPX
    .filter(stripMaybes)
    .filter((ac) => ac !== undefined)
    .reduce((prev, cur) => prev + cur.amount, 0)

  const esdecPrice = calculateEsdecPrice(choices, data)
  const cpxPrice = calculateCPXPrice(choices, data)
  const totalPrice = calculateTotalPrice(choices, data)

  useEffect(() => {
    if (isDebug) {
      debugConfigurator(choices, data, {
        totalEsdecProducts,
        totalCPXProducts,
        esdecPrice,
        cpxPrice,
        totalPrice,
      })
    }
  }, [
    choices,
    data,
    esdecPrice,
    cpxPrice,
    isDebug,
    totalEsdecProducts,
    totalCPXProducts,
    totalPrice,
  ])

  const refreshInverterTypes = useCallback(async () => {
    if (data.solarPanel?.sku && locale) {
      const models = await getInverterModels(
        client,
        locale,
        data.solarPanel.sku,
        choices.solarPanelPositions,
      )

      const types = getInverterTypes(models)

      setData((prevState) => ({
        ...prevState,
        inverterTypes: types,
      }))
    }
  }, [choices.solarPanelPositions, client, data.solarPanel?.sku, locale])

  const jsonPositions = JSON.stringify(choices.solarPanelPositions)

  useEffect(() => {
    refreshInverterTypes().then(() => {
      // do nothing
    })
  }, [refreshInverterTypes, jsonPositions])

  const solarPanelAmount = choices.solarPanelPositions.reduce(
    (prev, cur) => prev + cur.quantity,
    0,
  )

  useEffect(() => {
    setValue('solarPanelAmount', solarPanelAmount)
  }, [solarPanelAmount, setValue])

  useEffect(() => {
    if (data.inverterModel) {
      const specValue = data.inverterModel.specifications?.find(
        (spec) => spec?.field === 'inverterType',
      )?.value

      if (
        specValue?.__typename ===
        'PimcoreSpecificationsObjectBrickAttributeString'
      ) {
        const type = specValue.stringValue

        if (type === 'micro') {
          setValue('inverterModelAmount', solarPanelAmount)
        } else {
          setValue('inverterModelAmount', 1)
        }
      }
    }
  }, [solarPanelAmount, setValue, data.inverterModel])

  const value = useMemo(
    () => ({
      data,
      setData,
      choices,
      register,
      unregister,
      watch,
      control,
      setValue,
      esdecPrice,
      cpxPrice,
      totalPrice,
      totalEsdecProducts,
      totalCPXProducts,
    }),
    [
      data,
      setData,
      choices,
      register,
      unregister,
      watch,
      control,
      setValue,
      esdecPrice,
      cpxPrice,
      totalPrice,
      totalEsdecProducts,
      totalCPXProducts,
    ],
  )

  return (
    <ConfiguratorContext.Provider value={value}>
      {children}
    </ConfiguratorContext.Provider>
  )
}

export function useConfiguratorContext() {
  return useContext(ConfiguratorContext)
}
