import { useRouter } from 'next/router'
import type { NextRouter } from 'next/router'
import { useRef, useState } from 'react'

/**
 * @returns push function which functions without router initialization bug
 */
export const usePush = (): NextRouter['push'] => {
  const router = useRouter()
  const routerRef = useRef(router)

  routerRef.current = router

  const [{ push }] = useState<Pick<NextRouter, 'push'>>({
    push: (path) => routerRef.current.push(path, undefined, { shallow: true }),
  })

  return push
}
