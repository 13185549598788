import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import theme, { getTypography } from '../../theme'

/**
 * @deprecated Causes a lot of style sheets to be created due to large amount of props
 * Title used as a title on all three pages
 */
export const Title = styled('h1', {
  shouldForwardProp: (prop: string) =>
    !['color', 'marginBottom', 'hideOnMobile', 'hideOnDesktop'].includes(prop),
})<{
  color?: string
  marginBottom?: string
  hideOnMobile?: boolean
  hideOnDesktop?: boolean
}>`
  display: ${({ hideOnMobile }) => hideOnMobile && 'none'};
  ${getTypography('headingGalano', 18, 700)};
  color: ${({ color }) => color ?? theme.colors.primary};
  margin: 0;
  padding: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};

  @media ${minWidth('lg')} {
    display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'block')};
    ${getTypography('headingGalano', 32, 700)};
    padding: 0;
  }
`

/**
 * @deprecated Causes a lot of style sheets to be created due to large amount of props
 * SubTitle primarly used on the overview page for the summary
 */
export const SubTitle = styled('h1', {
  shouldForwardProp: (prop: string) =>
    !['color', 'marginBottom'].includes(prop),
})<{
  color?: string
  marginBottom?: string
}>`
  ${getTypography('headingGalano', 20, 700)};
  color: ${({ color }) => color ?? theme.colors.primary};
  margin: 0;
  padding: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};

  @media ${minWidth('lg')} {
    ${getTypography('headingGalano', 24, 700)}
    padding: 0;
  }
`

/**
 * @deprecated Causes a lot of style sheets to be created due to large amount of props
 * Heading used primarly for the options in the configurator and the product heading in the overview page
 */
export const HeadingOne = styled('h1', {
  shouldForwardProp: (prop: string) =>
    !['color', 'colorMobile', 'marginBottom'].includes(prop),
})<{
  color?: string
  colorMobile?: string
  marginBottom?: string
}>`
  ${getTypography('headingGalano', 16, 700)};
  color: ${({ color, colorMobile }) =>
    colorMobile ?? color ?? theme.colors.primary};
  margin: 0;
  padding: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};

  @media ${minWidth('lg')} {
    color: ${({ color }) => color ?? theme.colors.primary};
    ${getTypography('headingGalano', 20, 700)}
    padding: 0;
  }
`

/**
 * @deprecated Causes a lot of style sheets to be created due to large amount of props.
 * Currently used as heading in the cards for the solar panels and as subheading for the options
 */
export const HeadingTwo = styled('h2', {
  shouldForwardProp: (prop: string) =>
    !['color', 'marginBottom', 'fontFamily', 'disableResponsive'].includes(
      prop,
    ),
})<{
  color?: string
  marginBottom?: string
  fontFamily?: keyof typeof theme.fonts
  disableResponsive?: boolean
}>`
  color: ${({ color }) => color ?? theme.colors.primary};
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};
  padding: 0;

  ${({ fontFamily, disableResponsive }) =>
    getTypography(
      fontFamily !== undefined ? fontFamily : 'headingGalano',
      disableResponsive ? 16 : 14,
      700,
    )};

  @media ${minWidth('lg')} {
    ${({ fontFamily }) =>
      getTypography(
        fontFamily !== undefined ? fontFamily : 'headingGalano',
        16,
        700,
      )};
  }
`

/**
 * @deprecated Causes a lot of style sheets to be created due to large amount of props.
 * Used in most cards and the card for the mounting products
 */
export const HeadingThree = styled('h3', {
  shouldForwardProp: (prop: string) =>
    !['color', 'marginBottom'].includes(prop),
})<{
  color?: string
  marginBottom?: string
}>`
  ${getTypography('headingGalano', 14, 700)};
  color: ${({ color }) => color ?? theme.colors.primary};
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};
`

/**
 * @deprecated Causes a lot of style sheets to be created due to large amount of props.
 * Currently only used in the Help component
 */
export const Body = styled('p', {
  shouldForwardProp: (prop: string) =>
    !['color', 'fontFamily', 'isBold'].includes(prop),
})<{
  color?: string
  fontFamily?: keyof typeof theme.fonts
  isBold?: boolean
}>`
  ${({ fontFamily, isBold }) =>
    getTypography(
      fontFamily !== undefined ? fontFamily : 'bodyProxima',
      14,
      isBold ? 700 : 400,
    )};
  color: ${({ color }) => color ?? theme.colors.black};

  @media ${minWidth('lg')} {
    ${({ fontFamily, isBold }) =>
      getTypography(
        fontFamily !== undefined ? fontFamily : 'bodyProxima',
        16,
        isBold ? 700 : 400,
      )};
  }
`

/**
 * Used for short text elements, like prices
 */
export const Span = styled('span', {
  shouldForwardProp: (prop: string) =>
    !['color', 'marginBottom', 'withUnderline', 'isBold'].includes(prop),
})<{
  color?: string
  marginBottom?: string
  withUnderline?: boolean
  isBold?: boolean
}>`
  ${({ isBold }) => getTypography('bodyProxima', 14, isBold ? 700 : 400)};
  color: ${({ color }) => color ?? theme.colors.black};
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};
  text-decoration: ${({ withUnderline }) =>
    withUnderline ? 'underline' : 'none'};
`

/**
 * Mostly used to display specifications
 */
export const SmallText = styled('span', {
  shouldForwardProp: (prop: string) =>
    !['color', 'marginBottom'].includes(prop),
})<{
  color?: string
  marginBottom?: string
}>`
  ${getTypography('bodyProxima', 12, 400)};
  color: ${({ color }) => color ?? theme.colors.primary};
  margin: 0;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};
`
