import { SVGProps } from 'react'

export const SupportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 25"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.498 12.75a2.501 2.501 0 112.5 2.52 2.507 2.507 0 01-2.5-2.52v0z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.002 10.25a.25.25 0 10-.003.5.25.25 0 00.002-.5M18.114 10.25a.25.25 0 10-.003.5.25.25 0 00.003-.5"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M3.997 20.879v-.626a2.508 2.508 0 012.5-2.5H11.5a2.508 2.508 0 012.5 2.5v.626a.627.627 0 01-.624.625H4.622a.627.627 0 01-.625-.625v0z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.275 6.498a6.995 6.995 0 117.727 10.71"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
