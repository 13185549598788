import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { calculateCompatibleInvertersBySolarPanelIdQuery } from './calculateCompatibleInvertersBySolarPanelId'
import getAuthorizationContext from '../../lib/getAuthorizationContext'
import { Position } from '../context/ConfiguratorContext'
import {
  filterPositionsWithQuantity,
  stringifyPositions,
} from '../context/helpers'
import { getProductListingByShowInConfigurator } from '../product/get'
import { Product } from '../product/product'

/**
 * @param inverterModels
 * @returns
 */
export const getInverterTypes = (inverterModels: Product[]) => {
  if (inverterModels.length === 0) {
    return []
  }

  const inverterTypes = [
    ...new Set(
      inverterModels.map((invert) => {
        if (invert.specifications) {
          return invert.specifications.find(
            (spec) => spec?.field === 'inverterType',
          )?.value
        }

        return null
      }),
    ),
  ]

  const stringedTypes = inverterTypes.map((inv) => {
    if (inv?.__typename === 'PimcoreSpecificationsObjectBrickAttributeString') {
      return inv?.stringValue
    }

    return null
  })

  return stringedTypes.filter((val): val is string => typeof val === 'string')
}

/**
 * @param client apollo client used for querying
 * @param solarPanelId id of the solar panel which is used to get the compatible inverters
 * @param solarPanelPositions array of positions to use as configurations in the query
 * @returns array of inverters or an empty array
 */
export const getInverterModels = async (
  client: ApolloClient<unknown>,
  locale: string | undefined,
  solarPanelSku: string,
  solarPanelPositions: Position[],
): Promise<Product[]> => {
  const filtered = filterPositionsWithQuantity(solarPanelPositions)

  const { data } = await client.query({
    query: calculateCompatibleInvertersBySolarPanelIdQuery,
    variables: {
      sku: solarPanelSku,
      configurations: stringifyPositions(filtered),
      defaultLanguage: locale ?? null,
    },
    errorPolicy: 'all',
    context: getAuthorizationContext(),
  })

  const inverters =
    data.calculateCompatibleInvertersBySolarPanelSku?.compatibleInverters

  if (!inverters) {
    return []
  }

  const filteredInverters = inverters.filter(
    (node): node is Product =>
      node !== null && node !== undefined && node.published === true,
  )

  return filteredInverters
}

/**
 * @param client apollo client used for querying
 * @returns array of monitorings or an empty array
 */
export const getInverterMonitors = async (
  client: ApolloClient<NormalizedCacheObject>,
) => await getProductListingByShowInConfigurator(client, 'monitoring')

/**
 * @param client apollo client used for querying
 * @returns array of storages or an empty array
 *
 * Temporarily commented due to Pimcore structural changes
 */
export const getInverterStorages = async (
  client: ApolloClient<unknown>,
  sku: string,
): Promise<Product[]> => {
  // const { data } = await client.query({
  //   query: calculateCompatibleStorageSystemByInverterSkuQuery,
  //   variables: {
  //     sku,
  //   },
  //   errorPolicy: 'all',
  //   context: getAuthorizationContext(),
  // })

  // const storageSystems =
  //   data.calculateCompatibleStorageSystemByInverterSku?.compatibleStorageSystems

  const storageSystems = undefined

  if (!storageSystems) {
    return []
  }

  // const filteredStorage = storageSystems.filter(
  //   (node): node is Product =>
  //     node !== null && node !== undefined && node.published === true,
  // )

  return []
}
