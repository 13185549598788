import { TypedDocumentNode, gql } from '@apollo/client'

import {
  StoreDataMutation,
  StoreDataMutationVariables,
} from './storeData.generated'

const STORE_DATA = gql`
  mutation StoreData($data: JSON!) {
    storeData(data: $data)
  }
` as TypedDocumentNode<StoreDataMutation, StoreDataMutationVariables>

export { STORE_DATA }
