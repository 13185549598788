import styled from '@emotion/styled'

import { maxWidth } from '@emico/styles'

export const HideMobile = styled.div`
  display: flex;
  flex-direction: column;

  @media ${maxWidth('md')} {
    display: none;
  }
`
