import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'

import { maxWidth, minWidth } from '@emico/styles'

import NextLink from '../../components/NextLink'
import { getPriceWithCurrency } from '../../lib/getPriceWithCurrency'
import { useStoreConfig } from '../../lib/storeConfig'
import { useConfiguratorContext } from '../../shared/context/ConfiguratorContext'
import Container from '../../shared/ui/Container'
import theme, { getTypography } from '../../theme'
import { ProgressBar } from '../footer/ProgressBar'

const Footer = styled.footer`
  width: 100%;
  height: 79px;
  background-color: ${theme.colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  bottom: 0;
`

const StyledContainer = styled(Container)`
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${maxWidth('lg')} {
    padding: 0 ${theme.spacing.x2};
  }
`

const Column = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${theme.spacing.x3};

  @media ${minWidth('lg')} {
    width: 100%;
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media ${minWidth('lg')} {
    width: 50%;
  }
`

const Price = styled.span`
  ${getTypography('headingProxima', 20, 700)}
  color: ${theme.colors.black};
`

const HelperText = styled.span`
  display: none;
  ${getTypography('bodyGalano', 12, 400)}
  color: ${theme.colors.black};
  text-align: right;
  min-width: 178px;

  @media ${minWidth('lg')} {
    display: block;
  }
`

export const Button = styled.button`
  background-color: ${theme.colors.darkBlue};
  padding: ${theme.spacing.x15} ${theme.spacing.x4};
  color: ${theme.colors.white};
  ${getTypography('bodyGalano', 14, 700)};
  border: none;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.lightBlue};
  }
  &:focus {
    outline: none;
  }
`

const StyledNextLink = styled(NextLink)`
  color: ${theme.colors.white};
`

const FooterConfigurator = () => {
  const router = useRouter()
  const { storeConfig } = useStoreConfig()
  const { query } = router
  const { totalPrice, choices } = useConfiguratorContext()

  const handleProgressBar = () => {
    let count = 0

    if (choices?.solarPanel && choices?.solarPanel?.length > 0) {
      count += 1
    }
    if (choices?.inverterModel && choices?.inverterModel?.length > 0) {
      count += 1
    }
    if (
      choices?.inverterMonitor &&
      choices.inverterMonitor !== 'noMonitoring'
    ) {
      count += 1
    }
    if (choices?.inverterStorage && choices.inverterStorage !== 'noStorage') {
      count += 1
    }
    if (
      choices?.acMaterials &&
      choices.acMaterials.length > 0 &&
      !choices.acMaterials.some((acmaterial) => acmaterial === undefined)
    ) {
      count += 1
    }
    if (
      choices?.accessories &&
      choices.accessories.length > 0 &&
      !choices.accessories.some((accessory) => accessory === undefined)
    ) {
      count += 1
    }
    if (
      choices?.mountingEsdec &&
      choices.mountingEsdec.length > 0 &&
      !choices.mountingEsdec.some((mountEsdec) => mountEsdec === undefined)
    ) {
      count += 1
    }
    if (
      choices?.mountingCPX &&
      choices.mountingCPX.length > 0 &&
      !choices.mountingCPX.some((mountCPX) => mountCPX === undefined)
    ) {
      count += 1
    }

    return (100 / 8) * count
  }

  return (
    <Footer>
      <ProgressBar progress={handleProgressBar()} />

      <StyledContainer>
        <ColumnWrapper>
          <Column>
            <Price>
              {getPriceWithCurrency(
                totalPrice,
                storeConfig?.defaultDisplayCurrencyCode,
              )}
            </Price>
          </Column>

          <Column>
            <HelperText>
              <Trans>
                In the next step you will find an overview of your configuration
              </Trans>
            </HelperText>

            <StyledNextLink
              href={`/configurator/overview?h=${query.h}`}
              analyticsName="Go to configurator overview link"
              analyticsContext="Go to configurator overview"
            >
              <Button>
                <Trans>Complete</Trans>
              </Button>
            </StyledNextLink>
          </Column>
        </ColumnWrapper>
      </StyledContainer>
    </Footer>
  )
}

export default FooterConfigurator
