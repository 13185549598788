import { TypedDocumentNode, gql } from '@apollo/client'

import { ProductsQuery, ProductsQueryVariables } from './products.generated'

export const productsQuery = gql`
  query Products($skuArray: [String]) {
    products(filter: { sku: { in: $skuArray } }, pageSize: 1000) {
      items {
        sku
        priceRange {
          maximumPrice {
            finalPrice {
              value
            }
          }
        }
      }
    }
  }
` as TypedDocumentNode<ProductsQuery, ProductsQueryVariables>
