import { TypedDocumentNode, gql } from '@apollo/client'

import { productFragment } from './fragments'
import {
  GetProductListingQuery,
  GetProductListingQueryVariables,
} from './getProductListing.generated'

export const getProductListingQuery = gql`
  query GetProductListing(
    $filter: String
    $ids: String
    $defaultLanguage: String
  ) {
    getProductListing(
      filter: $filter
      ids: $ids
      defaultLanguage: $defaultLanguage
    ) {
      totalCount
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${productFragment}
` as TypedDocumentNode<GetProductListingQuery, GetProductListingQueryVariables>
