import styled from '@emotion/styled'

const LoadingSpan = styled('span', {
  shouldForwardProp: (prop: string) =>
    !['width', 'height', 'marginBottom'].includes(prop),
})<{
  width?: string
  height?: string
  marginBottom?: string
}>`
  display: inline-block;
  height: ${({ height }) => `${height ? height : '1em'}`};
  width: ${({ width }) => `${width ? width : 'initial'}`};
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  margin-bottom: ${({ marginBottom }) =>
    `${marginBottom ? marginBottom : '0'}`};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`

interface Props {
  width?: string
  height?: string
  marginBottom?: string
}

export const Loading = ({ width, height, marginBottom }: Props) => (
  <LoadingSpan width={width} height={height} marginBottom={marginBottom} />
)
