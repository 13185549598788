import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { stripMaybes } from '@emico-utils/graphql-data-utils'

import { getProductQuery } from './getProduct'
import { getProductListingQuery } from './getProductListing'
import { Product } from './product'
import { productsQuery } from './products'
import getAuthorizationContext from '../../lib/getAuthorizationContext'
import getStoreConfig from '../../lib/storeConfig'
import { ACMaterial } from '../ac-material/get'

/**
 * @param unknown
 * @returns
 */
const isProduct = (unknown: unknown): unknown is Product =>
  unknown !== undefined && unknown !== null

/**
 * @param client apollo client used for querying
 * @param id identifier of the product
 * @returns product or null
 */
export const getProduct = async (
  client: ApolloClient<unknown>,
  id: string | undefined,
  locale: string | undefined,
): Promise<Product | null> => {
  if (!id || id === '') {
    return null
  }

  const { data } = await client.query({
    query: getProductQuery,
    variables: {
      id: parseInt(id),
      defaultLanguage: locale ?? null,
    },
    errorPolicy: 'all',
    context: getAuthorizationContext(),
  })

  const product = data.getProduct

  if (!product || !isProduct(product) || product.published === false) {
    return null
  }

  return product
}

/**
 * @param client apollo client used for querying
 * @param filter filter string used in the Pimcore query
 * @returns array of products or an empty array
 */
export const getProductListing = async (
  client: ApolloClient<NormalizedCacheObject>,
  filter?: string,
  ids?: string,
): Promise<Product[]> => {
  const storeConfig = await getStoreConfig(client)

  const { data } = await client.query({
    query: getProductListingQuery,
    variables: {
      ...(ids ? { ids: ids } : { ids: null }),
      ...(filter ? { filter: filter } : { filter: null }),
      defaultLanguage: storeConfig?.locale ?? null,
    },
    errorPolicy: 'all',
    context: getAuthorizationContext(),
  })

  const edges = data.getProductListing?.edges

  if (!edges) {
    return []
  }

  const nodes = edges.map((edge) => edge?.node)

  return nodes.filter(
    (node): node is Product =>
      node !== undefined && node !== null && node.published === true,
  )
}

/**
 * @param client apollo client used for querying
 * @param key key used in the showInConfigurator attribute to filter upon
 * @returns array of products or an empty array
 */
export const getProductListingByShowInConfigurator = async (
  client: ApolloClient<NormalizedCacheObject>,
  key: string,
): Promise<Product[]> => {
  const products = await getProductListing(
    client,
    `{"showInConfigurator": "${key}"}`,
  )

  return products.filter((prod) => prod.published === true)
}

export const getProductListingBySkus = async (
  client: ApolloClient<NormalizedCacheObject>,
  skus: string[],
) => {
  if (skus.length === 0) {
    return []
  }

  const createFilterString = skus.map((sku) => `{"sku": "${sku}"}`).join(',')

  const products = await getProductListing(
    client,
    `{"$or": [${createFilterString}]}`,
  )

  return products.filter((prod) => prod.published === true)
}

export const getProductListingByIds = async (
  client: ApolloClient<NormalizedCacheObject>,
  ids: string[],
) => {
  const products = await getProductListing(client, undefined, ids.join())

  return products.filter((prod) => prod.published === true)
}

export const getProductPrices = async (
  client: ApolloClient<unknown>,
  products: Product[],
) => {
  if (products.length === 0) {
    return []
  }

  const skuArray = products.map((product) => product.sku)

  const { data } = await client.query({
    query: productsQuery,
    variables: {
      skuArray,
    },
    errorPolicy: 'all',
    context: getAuthorizationContext(),
  })

  if (!data.products?.items) {
    return products.filter((prod) => prod.published === true)
  }

  const productPrices = data.products.items.filter(stripMaybes)

  return products.map((product) => {
    const price = productPrices.find(
      (productPrice) => productPrice.sku === product.sku,
    )?.priceRange.maximumPrice?.finalPrice.value

    return {
      ...product,
      price: price ?? 0,
    }
  })
}

export const getProductPrice = async (
  client: ApolloClient<unknown>,
  product: Product,
) => {
  const [productWithPrice] = await getProductPrices(client, [product])

  return productWithPrice
}

export const getACMaterialsPrices = async (
  client: ApolloClient<unknown>,
  acMaterials: ACMaterial[],
) => {
  if (acMaterials.length === 0) {
    return []
  }

  const skuArray = acMaterials.map((acMaterial) => acMaterial.sku)

  const { data } = await client.query({
    query: productsQuery,
    variables: {
      skuArray,
    },
    errorPolicy: 'all',
    context: getAuthorizationContext(),
  })

  if (!data.products?.items) {
    return acMaterials.filter((acMaterial) => acMaterial.published === true)
  }

  const productPrices = data.products.items.filter(stripMaybes)

  return acMaterials.map((acMaterial) => {
    const price = productPrices.find(
      (productPrice) => productPrice.sku === acMaterial.sku,
    )?.priceRange.maximumPrice?.finalPrice.value

    return {
      ...acMaterial,
      price: price ?? 0,
    }
  })
}
