import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

import { STORE_DATA } from './storeData'
import { StoreDataMutationVariables } from './storeData.generated'
import { useQueryState } from '../context/useQueryState'

/**
 * @returns async function to fire a query to store data and get the hash back
 */
export const useStoreData = () => {
  const client = useApolloClient()
  const [variables, setVariables] = useState<StoreDataMutationVariables>({
    data: '',
  })
  const [storing, setStoring] = useState(false)
  const [called, setCalled] = useState(false)
  const [, setHashQuery] = useQueryState('h')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStoring(false)
    }, 3000)

    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout)
    }
  }, [storing])

  const storeData = useCallback(
    async (vars: StoreDataMutationVariables) => {
      const { data, ...others } = await client.mutate({
        mutation: STORE_DATA,
        variables: vars,
      })

      setHashQuery(data?.storeData ?? '')

      return {
        hash: data?.storeData ?? '',
        ...others,
      }
    },
    [client, setHashQuery],
  )

  const updateData = async (
    vars: StoreDataMutationVariables,
    force?: boolean,
  ) => {
    setVariables(vars)
    setCalled(true)
    if (force) {
      setStoring(false)
    }
  }

  useEffect(() => {
    if (!storing && called) {
      setStoring(true)
      setCalled(false)
      storeData(variables)
    }
  }, [called, storeData, storing, variables])

  return updateData
}
