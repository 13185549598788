import { TypedDocumentNode, gql } from '@apollo/client'

import {
  CalculateCompatibleInvertersBySolarPanelIdQuery,
  CalculateCompatibleInvertersBySolarPanelIdQueryVariables,
} from './calculateCompatibleInvertersBySolarPanelId.generated'
import { productFragment } from '../product/fragments'

export const calculateCompatibleInvertersBySolarPanelIdQuery = gql`
  query CalculateCompatibleInvertersBySolarPanelId(
    $sku: String!
    $configurations: String!
    $defaultLanguage: String
  ) {
    calculateCompatibleInvertersBySolarPanelSku(
      sku: $sku
      configurations: $configurations
      defaultLanguage: $defaultLanguage
    ) {
      totalCount
      compatibleInverters {
        ...ProductFragment
      }
    }
  }
  ${productFragment}
` as TypedDocumentNode<
  CalculateCompatibleInvertersBySolarPanelIdQuery,
  CalculateCompatibleInvertersBySolarPanelIdQueryVariables
>
