export type Route = {
  [key: string]: string
}

export const routes = {
  cart: '/checkout/cart',
  checkout: {
    shipping: '/checkout/shipping',
    payment: '/checkout/payment',
  },
  configurator: {
    base: '/configurator',
    start: '/configurator/start',
    overview: '/configurator/overview',
  },
}
