import { useApolloClient } from '@apollo/client'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Control as ControlType,
  useForm,
  UseFormRegister,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form'

import { Choices } from './ConfiguratorContext'
import { calculateTotalPrice } from './helpers'
import { ElektramatConfigurationFragment } from '../elektramat/fragments.generated'
import { useStoreData } from '../hash/useStoreData'
import { getProductPrice, getProductPrices } from '../product/get'
import { Product } from '../product/product'

export interface Products {
  solarPanel?: Product | null
  inverterModels?: Product[]
  inverterMonitors?: Product[]
  inverterStorages?: Product[]
  acMaterials?: Product[]
  accessories?: Product[]
  mountingEsdec?: Product[]
  mountingCPX?: Product[]
}

interface ElektramatConfiguration extends ElektramatConfigurationFragment {
  quantity: string | null
}

export interface Data extends Products {
  elektramatModel?: ElektramatConfiguration | null
}

interface OverviewContextInterface {
  choices?: Choices
  data?: Data
  watch?: UseFormWatch<Choices>
  control?: ControlType<Choices>
  register?: UseFormRegister<Choices>
  unregister?: UseFormUnregister<Choices>
  areProductsPresent: boolean
  totalPrice?: number
}

const OverviewContext = createContext<OverviewContextInterface>({
  areProductsPresent: false,
})

interface Props {
  children: JSX.Element
  initialChoices: Choices
  initialData: Data
}

export const OverviewProvider = ({
  children,
  initialChoices,
  initialData,
}: Props) => {
  const client = useApolloClient()
  const { watch, control, unregister, register } = useForm<Choices>({
    defaultValues: initialChoices,
  })
  const [initialized, setInitialized] = useState(false)
  const [data, setData] = useState(initialData)

  const storeData = useStoreData()

  const choices = watch()

  const getDataPrices = useCallback(async () => {
    let solarPanel = data.solarPanel

    if (solarPanel) {
      solarPanel = await getProductPrice(client, solarPanel)
    }

    let inverterModels = data.inverterModels

    if (inverterModels) {
      inverterModels = await getProductPrices(client, inverterModels)
    }

    let inverterMonitors = data.inverterMonitors

    if (inverterMonitors) {
      inverterMonitors = await getProductPrices(client, inverterMonitors)
    }

    let inverterStorages = data.inverterStorages

    if (inverterStorages) {
      inverterStorages = await getProductPrices(client, inverterStorages)
    }

    let acMaterials = data.acMaterials

    if (acMaterials) {
      acMaterials = await getProductPrices(client, acMaterials)
    }

    let accessories = data.accessories

    if (accessories) {
      accessories = await getProductPrices(client, accessories)
    }

    let mountingEsdec = data.mountingEsdec

    if (mountingEsdec) {
      mountingEsdec = await getProductPrices(client, mountingEsdec)
    }

    let mountingCPX = data.mountingCPX

    if (mountingCPX) {
      mountingCPX = await getProductPrices(client, mountingCPX)
    }

    return {
      ...data,
      solarPanel,
      inverterModels,
      inverterMonitors,
      inverterStorages,
      acMaterials,
      accessories,
      mountingEsdec,
      mountingCPX,
    }
  }, [client, data])

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      getDataPrices().then((res) => {
        setData(res)
      })
    }
  }, [getDataPrices, initialized])

  useEffect(() => {
    if (!choices.elektramatModel) {
      unregister('elektramatModel')
    }
  }, [choices.elektramatModel, unregister])

  useEffect(() => {
    if (choices.inverterModelAmount === 0) {
      unregister('inverterModel')
      unregister('inverterType')
      unregister('inverterModelAmount')
    }
  }, [choices.inverterModelAmount, unregister])

  useEffect(() => {
    if (choices.solarPanelAmount === 0) {
      unregister('solarPanel')
      unregister('solarPanelPositions')
      unregister('solarPanelAmount')
    }
  }, [choices.solarPanelAmount, unregister])

  useEffect(() => {
    if (
      choices.acMaterials &&
      choices.acMaterials.some((ac) => ac !== null && ac.amount === 0)
    ) {
      const zeroAmountACMaterial = choices.acMaterials.find(
        (ac) => ac?.amount === 0,
      )

      if (!zeroAmountACMaterial) {
        return
      }
      unregister(`acMaterials.${parseInt(zeroAmountACMaterial.id)}`)
    }
  }, [choices.acMaterials, choices.solarPanelAmount, unregister])

  useEffect(() => {
    if (
      choices.accessories &&
      choices.accessories.some((ac) => ac !== null && ac.amount === 0)
    ) {
      const zeroAmountAccessorie = choices.accessories.find(
        (ac) => ac?.amount === 0,
      )

      if (!zeroAmountAccessorie) {
        return
      }
      unregister(`accessories.${parseInt(zeroAmountAccessorie.id)}`)
    }
  }, [choices.accessories, choices.solarPanelAmount, unregister])

  useEffect(
    () =>
      watch((values) => {
        storeData({
          data: JSON.stringify(values),
        })
      }).unsubscribe,
    [storeData, watch],
  )

  const isSolarPanelChosen = () =>
    choices.solarPanelAmount && choices.solarPanelAmount > 0

  const isInverterModelChosen = () =>
    choices.inverterModel &&
    choices.inverterModelAmount &&
    choices.inverterModelAmount > 0

  const isInverterMonitorChosen = () =>
    choices.inverterMonitor !== '' && choices.inverterMonitor !== undefined

  const isACMaterialChosen = () =>
    choices.acMaterials.some((ac) => ac && ac.amount && ac.amount > 0)

  const areProductsPresent =
    isSolarPanelChosen() ||
    isInverterModelChosen() ||
    isACMaterialChosen() ||
    isInverterMonitorChosen()

  const totalPrice = calculateTotalPrice(choices, data)

  const value = {
    choices,
    watch,
    control,
    register,
    unregister,
    data,
    areProductsPresent,
    totalPrice,
  }

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  )
}

export function useOverviewContext() {
  return useContext(OverviewContext)
}
