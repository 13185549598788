import { ApolloClient, gql, TypedDocumentNode, useQuery } from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { StoreConfigQuery } from './storeConfig.generated'

export const storeConfigFragment = gql`
  fragment StoreConfigFragment on StoreConfig {
    id
    code
    storeName
    websiteId
    locale
    rootCategoryId
    defaultDisplayCurrencyCode
    defaultCountryId
    rootCategoryUid
    baseLinkUrl
    baseUrl

    # Custom 4blue additions
    fourblueConfiguratorPanelclawEnabled
    fourblueConfiguratorElektramatEnabled
    fourblueConfiguratorEsdecEnabled
    fourblueConfiguratorCategoryAcEnabled
    fourblueEsdecClientId
    fourbluePanelClawClientId
  }
`

const query = gql`
  query StoreConfig {
    storeConfig {
      ...StoreConfigFragment
    }
  }
  ${storeConfigFragment}
` as TypedDocumentNode<StoreConfigQuery>

export default async function getStoreConfig(client: ApolloClient<unknown>) {
  const { data } = await client.query({
    query,
    context: getCacheableContext(),
  })

  return data.storeConfig
}

export function useStoreConfig() {
  const { data, loading } = useQuery(query, {
    context: getCacheableContext(),
  })

  return {
    storeConfig: data?.storeConfig,
    isLoading: loading,
  }
}
