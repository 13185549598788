import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import { usePush } from '../router/usePush'

/**
 * @param key key used in the query as identifier
 * @returns array with two entries, the value of the state and the setState function
 */
export const useQueryState = (
  key: string,
): [
  value: string | undefined,
  setState: (value: string) => Promise<boolean>,
] => {
  const router = useRouter()
  const { query: queryRouter, pathname } = router
  const push = usePush()
  const [query] = useState(queryRouter)

  const [value, setValue] = useState<string>()

  const initState = useCallback(() => {
    const queryValue = query[key]

    if (queryValue && typeof queryValue === 'string') {
      setValue(queryValue)
    }
  }, [key, query])

  const setState = useCallback(
    (value: string) => {
      setValue(value)

      const queryParamKeys = Object.keys(query)

      const filtered = queryParamKeys.filter((par) => par !== key)

      const queryParams = filtered.map((key) => `${key}=${query[key]}`)

      const newQueryParams = [...queryParams, `${key}=${value}`]

      const uniqueQueryParams = [...new Set(newQueryParams)]

      const queryParamsString = uniqueQueryParams.join('&')

      return push(`${pathname}?${queryParamsString}`)
    },
    [key, push, query, pathname],
  )

  useEffect(() => {
    initState()
  }, [initState])

  return [value, setState]
}
