import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'

import { minWidth } from '@emico/styles'

import NextLink from '../../components/NextLink'
import { routes } from '../../lib/routes'
import { useOverviewContext } from '../../shared/context/OverviewContext'
import { BackIcon } from '../../shared/icon/BackIcon'
import { CrossIcon } from '../../shared/icon/CrossIcon'
import { SupportIcon } from '../../shared/icon/SupportIcon'
import Container from '../../shared/ui/Container'
import { HideMobile } from '../../shared/ui/HideMobile'
import { toggleChat } from '../../shared/zendesk/toggleChat'
import theme, { getTypography } from '../../theme'
import Logo from '../header/Logo'

const Header = styled.header`
  width: 100%;
  height: 92px;
  border-bottom: 1px solid black;
  background-color: ${theme.colors.primary};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media ${minWidth('md')} {
    height: 76px;
  }
`

const StyledContainer = styled(Container)`
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledLogo = styled(Logo)`
  display: block;
  font-size: 90px;
  color: ${theme.colors.white};
  cursor: pointer;
`

const HeaderText = styled.span`
  display: none;

  @media ${minWidth('md')} {
    display: block;
    ${getTypography('headingProxima', 14, 600)}
    color: ${theme.colors.white};
  }
`

const Text = styled('span', {
  shouldForwardProp: (prop: string) => !['isExitButton'].includes(prop),
})<{
  isExitButton?: boolean
}>`
  display: ${({ isExitButton }) => (isExitButton ? 'none' : 'block')};
  ${getTypography('headingProxima', 14, 400)}
  color: ${theme.colors.white};

  @media ${minWidth('md')} {
    display: block;
    font-weight: ${theme.fontWeights[600]};
  }
`

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x4};
`

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const HelpButton = styled(ButtonUnstyled)`
  width: 100%;
  padding: ${theme.spacing.x3} ${theme.spacing.x4};
  white-space: nowrap;
  cursor: pointer;

  @media ${minWidth('md')} {
    border-left: 1px solid ${theme.colors.blue};
    border-bottom: 1px solid ${theme.colors.blue};
    border-top: 1px solid ${theme.colors.lightBlue};
    border-right: 1px solid ${theme.colors.blue};
    background-color: rgba(243, 242, 244, 0.1);
  }
`

const ExitButton = styled.div`
  font: inherit;
  margin: 0;
  white-space: nowrap;
  border: 1px solid ${theme.colors.blue};
  padding: ${theme.spacing.x1};
  cursor: pointer;
  background-color: rgba(243, 242, 244, 0.1);

  @media ${minWidth('md')} {
    padding: ${theme.spacing.x3} ${theme.spacing.x4};
    border-top: 1px solid ${theme.colors.lightBlue};
    background-color: rgba(243, 242, 244, 0.1);
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:active,
  &:disabled {
    background: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`

const StyledSupportIcon = styled(SupportIcon)`
  display: none;

  @media ${minWidth('md')} {
    display: block;
    color: ${theme.colors.white};
    width: 24px;
    height: 24px;
  }
`

const StyledCrossIcon = styled(CrossIcon)`
  width: 24px;
  height: 24px;
`

const OverviewButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray};
  padding: 10px 18px;
  ${getTypography('bodyProxima', 14, 600)}
  cursor: pointer;
`

const StyledBackIcon = styled(BackIcon)`
  color: ${theme.colors.white};
  height: 10px;
  width: 10px;
  margin-right: 5px;
`

const HeaderConfigurator = () => {
  const router = useRouter()
  const activeStoreView = useActiveStoreView()
  const baseUrl = activeStoreView.basename
  const { pathname, query } = router

  const { areProductsPresent } = useOverviewContext()

  return (
    <Header>
      <StyledContainer>
        <LeftColumn>
          <a href={baseUrl}>
            <StyledLogo colorTheme="white" />
          </a>

          {pathname === routes.configurator.overview ? (
            areProductsPresent ? (
              <HideMobile>
                <NextLink
                  href={`/configurator?h=${query.h}`}
                  analyticsContext="Exit configurator with logo"
                  analyticsName="Exit configurator with logo"
                >
                  <OverviewButton>
                    <StyledBackIcon />

                    <Trans>Edit configuration</Trans>
                  </OverviewButton>
                </NextLink>
              </HideMobile>
            ) : (
              <NextLink
                href="/start"
                analyticsContext="Exit configurator with logo"
                analyticsName="Exit configurator with logo"
              >
                <OverviewButton>
                  <StyledBackIcon />

                  <Trans>Start a configuration</Trans>
                </OverviewButton>
              </NextLink>
            )
          ) : (
            <HeaderText>
              <Trans>
                Put together your own solar <br /> system in just a few steps
              </Trans>
            </HeaderText>
          )}
        </LeftColumn>

        <RightColumn>
          <HelpButton
            analyticsContext="configuratorHeader"
            analyticsName="help"
            onPress={() => toggleChat()}
          >
            <Wrapper>
              <Text>
                <Trans>Need help?</Trans>
              </Text>
              <StyledSupportIcon />
            </Wrapper>
          </HelpButton>

          <a href={activeStoreView.basename}>
            <ExitButton>
              <Wrapper>
                <Text isExitButton>
                  <Trans>Stop configuration</Trans>
                </Text>
                <StyledCrossIcon />
              </Wrapper>
            </ExitButton>
          </a>
        </RightColumn>
      </StyledContainer>
    </Header>
  )
}

export default HeaderConfigurator
