import { getCustomerTokenHeaderObject } from '@emico-hooks/login-token'

import loginTokenVar from './loginTokenVar'

const getAuthorizationContext = () => {
  // Get the authorization token from local storage if it exists
  const token = loginTokenVar()

  return getCustomerTokenHeaderObject(token)
}

export default getAuthorizationContext
