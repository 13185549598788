import styled from '@emotion/styled'

import theme from '../../theme'

const Bar = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray};
  height: 3px;
`

const Progress = styled.span`
  display: block;
  height: 100%;
  transition: width 0.1s ease;
  background-color: ${theme.colors.accentGreen};
`

interface Props {
  /** Progress 0-100 */
  progress: number
}

/**
 * component used to show the progress the user has made through a percentage
 * @param props
 * @returns ProgressBar component
 */
export const ProgressBar = ({ progress }: Props) => (
  // Progress directly on style to prevent creating many style sheets. https://emotion.sh/docs/best-practices#use-the-style-prop-for-dynamic-styles
  <Bar>
    <Progress style={{ width: `${progress}%` }} />
  </Bar>
)
